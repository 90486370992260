<template>
  <div id="end-of-stage-slide">
    <h3 class="header-text mb-4" v-html="heading" />

    <ct-card-deck>
      <ct-card
        v-if="!currentStageSlidesComplete && !ctaStage"
        @click="goToFirstIncompleteSlideOfCurrentStage"
      >
        <div class="card__image">
          <picture>
            <source
              :srcset="require('@images/illustrations/stageline/stageline-incomplete-steps.webp')"
              type="image/webp"
            >
            <img
              :src="require('@images/illustrations/stageline/stageline-incomplete-steps.png')"
              alt="woman on staircase"
              class="image"
            >
          </picture>
        </div>
        <div class="card__text">
          Go back and complete uncompleted steps.
        </div>
      </ct-card>

      <ct-card
        v-if="!ctaStage"
        @click="completeStageAndContinue"
      >
        <div class="card__image">
          <picture>
            <source
              :srcset="require('@images/illustrations/stageline/stageline-continue-sign.webp')"
              type="image/webp"
            >
            <img
              :src="require('@images/illustrations/stageline/stageline-continue-sign.png')"
              alt="Continue sign"
              class="image"
            >
          </picture>
        </div>
        <div class="card__text">
          I'm ready to take my business to the next stage.
        </div>
      </ct-card>
      <ct-card @click="$router.push({ name: 'root' }).catch(() => {})">
        <div class="card__image">
          <corporation-svg class="card__image" alt="buildings" />
        </div>
        <div class="card__text">
          I would like to manage another business.
        </div>
      </ct-card>
      <feedback-tool
        class="feedback-tool"
        :source-category="sourceCategory"
      />
    </ct-card-deck>

    <b-button
      v-if="!ctaStage"
      variant="default"
      class="back-button"
      aria-label="previous button"
      @click="$emit('back')"
    >
      <fa-icon icon="chevron-left" class="fa-xs" />&nbsp;
      {{ 'Previous' }}
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as DOMPurify from 'dompurify'

export default {
  name: 'EndOfStageSlide',
  components: {
    CtCard:         () => import('@/components/shared/CtCard'),
    CtCardDeck:     () => import('@/components/shared/CtCardDeck'),
    FeedbackTool:   () => import('@/components/StagelineV2/clientFeedback/FeedbackTool'),
    CorporationSvg: () => import('@images/illustrations/stageline/Corporation_1.svg'),
  },
  data() {
    return {
      sourceCategory: 'stage',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentStage',
      'currentStageSlidesComplete',
    ]),
    heading() {
      const title = this.currentStageSlidesComplete
        ? "Looks like your business is done with this stage! <strong>What's next?</strong>"
        : "You haven't completed all the steps in this stage. Are you sure you want to continue?"
      return DOMPurify.sanitize(title)
    },
    ctaStage() {
      return this.currentStage?.name === 'corporate_transparency_act'
    },
  },
  methods: {
    ...mapActions('stageline', [
      'goToFirstIncompleteSlideOfCurrentStage',
      'completeStage',
    ]),
    async completeStageAndContinue() {
      await this.completeStage(this.currentStage.id)
      this.$emit('continue')
    },
  },
}
</script>

<style lang="scss" scoped>
#end-of-stage-slide {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-text {
    display: inline-block;
    text-align: left;
  }
  .row-or__text {
    color: $ct-ui-font-color-secondary;
    margin: 1.25em;
    background: white;
    text-align: center;
  }
  .card__text {
    margin-left: 1em;
    display: inline-block;
    text-align: left;
  }
  .feedback-tool {
    align-self: flex-start;
  }
  .back-button {
    align-self: self-end;
    color: $ct-ui-primary;

    &:hover {
      background: $ct-ui-primary-light;
    }
  }
}
</style>
